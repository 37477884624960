import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export const UserCredit: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 19 20" width="19px" height="20px" {...props}>
      <g clipPath="url(#clip0_15_15743)">
        <path
          d="M10.4777 18.8401C10.2838 18.865 10.0976 18.8798 9.90145 18.8867C8.21419 18.9301 6.56057 18.2587 5.24099 16.9795C3.52139 15.3052 2.50933 12.7931 2.47384 10.0737C2.39102 5.10104 5.59162 0.936879 9.60864 0.831334C11.2947 0.778939 12.9495 1.45939 14.2603 2.7397C15.9799 4.41396 16.9919 6.92605 17.0263 9.6365C17.1056 14.3728 14.2138 18.3606 10.4777 18.8401ZM9.11869 1.55015C5.70857 1.98777 3.07417 5.67841 3.13511 10.0617C3.17403 12.5985 4.11021 14.9381 5.69686 16.4837C6.90341 17.6498 8.34171 18.2487 9.86691 18.1988C13.5345 18.0925 16.4359 14.2492 16.3639 9.63952C16.3249 7.10275 15.3888 4.76313 13.8021 3.21757C12.5944 2.04246 11.1573 1.45252 9.63092 1.49352C9.4635 1.51501 9.28611 1.52866 9.11869 1.55015Z"
          fill="#FFC700"
        />
        <path
          d="M7.13023 18.4408C7.09498 18.4453 7.05858 18.4409 7.01338 18.4376C3.64758 17.6852 1.16195 14.1596 1.09032 10.0419C1.01904 5.57789 3.74537 1.76612 7.43132 1.17467C7.58993 1.15431 7.78457 1.2751 7.81639 1.45322C7.8482 1.63134 7.72026 1.82086 7.54402 1.84347C4.17298 2.37629 1.68761 5.90205 1.76155 10.0377C1.81936 13.8384 4.09195 17.0997 7.152 17.7729C7.32748 17.8142 7.44856 17.99 7.40988 18.1771C7.38423 18.3262 7.2624 18.4238 7.13023 18.4408Z"
          fill="#FFC700"
        />
        <path
          d="M7.05408 1.93359C6.87785 1.95621 6.71961 1.83986 6.6878 1.66174C6.65483 1.47465 6.76745 1.30532 6.9502 1.26365L8.76042 0.922024C8.95848 0.860165 9.11251 1.01349 9.15314 1.19048C9.18611 1.37757 9.07348 1.5469 8.89074 1.58857L7.08052 1.9302C7.07171 1.93133 7.0629 1.93246 7.05408 1.93359Z"
          fill="#FFC700"
        />
        <path
          d="M8.50307 18.7382C8.46783 18.7427 8.43143 18.7383 8.39503 18.7338L6.92956 18.4117C6.74528 18.3716 6.63301 18.1947 6.67169 18.0075C6.71037 17.8203 6.88391 17.707 7.06819 17.7471L8.53366 18.0692C8.71795 18.1093 8.83022 18.2862 8.79154 18.4734C8.75707 18.6236 8.64406 18.7201 8.50307 18.7382Z"
          fill="#FFC700"
        />
        <path
          d="M10.3201 17.0566C10.1791 17.0747 10.0369 17.0838 9.89481 17.0929C8.63626 17.1269 7.43551 16.6341 6.43279 15.6605C5.04232 14.3175 4.23106 12.2534 4.1914 9.99002C4.1259 5.98992 6.57375 2.66029 9.64292 2.56706C12.311 2.48887 14.6517 4.88512 15.2209 8.27396C15.2539 8.46104 15.1325 8.6315 14.9486 8.66421C14.7436 8.67229 14.5953 8.56378 14.5635 8.38566C14.0556 5.33513 11.9961 3.1761 9.66751 3.24717C6.96421 3.32989 4.80789 6.3488 4.86263 9.98587C4.89576 12.0588 5.63996 13.9493 6.88981 15.1737C7.75957 16.0186 8.79136 16.451 9.87137 16.4218C12.1371 16.3496 14.069 14.2341 14.558 11.2743C14.5879 11.0883 14.7603 10.9659 14.9434 10.9971C15.1265 11.0283 15.2476 11.204 15.2178 11.3901C14.7008 14.481 12.7168 16.749 10.3201 17.0566Z"
          fill="#FFC700"
        />
        <path
          d="M15.0563 10.3448C15.0387 10.3471 15.0211 10.3494 15.0035 10.3516C14.815 10.3485 14.6697 10.194 14.672 10.0024C14.6758 9.89261 14.6709 9.78392 14.6747 9.67411L14.7398 9.48355C14.7398 9.48355 14.7106 9.18665 15.0148 9.18405C15.329 9.18929 15.334 9.5075 15.334 9.5075L15.3371 9.67109C15.3421 9.77978 15.3394 9.89855 15.3367 10.0173C15.3498 10.1887 15.2238 10.3234 15.0563 10.3448Z"
          fill="#FFC700"
        />
        <path
          d="M8.43858 13.4899C8.3769 13.4978 8.31291 13.4878 8.24663 13.4599C8.13169 13.4018 8.06387 13.292 8.05658 13.1654L7.99697 10.6769L6.15055 8.99156C6.05974 8.91212 6.01721 8.79003 6.04631 8.66786C6.06661 8.54682 6.15318 8.45372 6.27425 8.41996L8.54648 7.70019L9.37766 5.66215C9.42554 5.54668 9.55082 5.47594 9.65541 5.45341C9.77877 5.43758 9.89486 5.50467 9.96154 5.60544L11.1908 7.50661L12.9681 7.81603C13.0784 7.83831 13.1681 7.9088 13.216 8.00286C13.265 8.10589 13.2624 8.22467 13.2022 8.31438L12.2316 10.0332L12.6651 12.155C12.6888 12.2703 12.6509 12.3936 12.5708 12.4677C12.4919 12.5507 12.3697 12.5755 12.2582 12.5442L10.4468 12.0388L8.59029 13.4158C8.54202 13.4584 8.49145 13.4831 8.43858 13.4899ZM7.03287 8.88745L8.55246 10.2594C8.62334 10.3232 8.66128 10.4094 8.66512 10.5091L8.71319 12.489L10.1854 11.3982C10.2666 11.3331 10.3724 11.3195 10.4651 11.344L11.9045 11.7424L11.5515 10.0385C11.5323 9.95898 11.5484 9.87492 11.5921 9.79644L12.3881 8.39152L10.9406 8.13998C10.8491 8.1244 10.7705 8.07071 10.7161 7.9957L9.74477 6.49873L9.10089 8.11188C9.05952 8.20829 8.98712 8.27225 8.89249 8.30261L7.03287 8.88745Z"
          fill="#FFC700"
        />
        <path
          d="M7.13023 18.4408C7.09498 18.4453 7.05858 18.4409 7.01338 18.4376C3.64758 17.6852 1.16195 14.1596 1.09032 10.0419C1.01904 5.57789 3.74537 1.76612 7.43132 1.17467C7.58993 1.15431 7.78457 1.2751 7.81639 1.45322C7.8482 1.63134 7.72026 1.82086 7.54402 1.84347C4.17298 2.37629 1.68761 5.90205 1.76155 10.0377C1.81936 13.8384 4.09195 17.0997 7.152 17.7729C7.32748 17.8142 7.44856 17.99 7.40988 18.1771C7.38423 18.3262 7.2624 18.4238 7.13023 18.4408Z"
          fill="#FFC700"
        />
        <path
          d="M10.3946 3.68223C10.3594 3.68675 10.323 3.68231 10.2866 3.67787C10.2039 3.66116 10.1123 3.64558 10.0207 3.63C9.83758 3.59884 9.70884 3.43316 9.7387 3.24712C9.75976 3.06221 9.931 2.93091 10.1141 2.96207C10.2145 2.97652 10.3249 2.9988 10.4252 3.01325C10.6095 3.05338 10.7218 3.23028 10.6831 3.41745C10.6475 3.55868 10.5356 3.66413 10.3946 3.68223Z"
          fill="#FFC700"
        />
        <path
          d="M9.98166 16.6536C9.84949 16.6706 9.72497 16.6775 9.59165 16.6855C8.39708 16.7294 7.25265 16.2568 6.3009 15.3313C4.99127 14.0599 4.2214 12.1089 4.19784 9.97103C4.14999 6.1782 6.50053 2.99771 9.39232 2.91813C9.57967 2.91231 9.73255 3.05668 9.73908 3.24716C9.74561 3.43763 9.62302 3.59913 9.4169 3.59825C6.8592 3.69872 4.81435 6.53934 4.86025 9.96801C4.88725 11.9233 5.58124 13.7018 6.75791 14.8444C7.57672 15.6413 8.55103 16.0446 9.55826 16.0065C12.1424 15.9026 14.1608 13.0654 14.1149 9.63672C14.0794 6.91731 12.7509 4.59159 10.8092 3.83865C10.6391 3.76938 10.5509 3.57115 10.6184 3.39851C10.6858 3.22588 10.8804 3.13713 11.0505 3.2064C13.2474 4.06326 14.7514 6.63976 14.7861 9.63258C14.8397 13.2607 12.7133 16.3031 9.98166 16.6536Z"
          fill="#FFC700"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_15_15743"
          x1="4.12219"
          y1="4.06124"
          x2="17.5241"
          y2="12.8671"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4B557" />
          <stop offset="0.453125" stopColor="brand.primary" />
          <stop offset="1" stopColor="#FD4767" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15_15743"
          x1="1.62941"
          y1="4.34563"
          x2="11.2779"
          y2="7.57074"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4B557" />
          <stop offset="0.453125" stopColor="brand.primary" />
          <stop offset="1" stopColor="#FD4767" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_15_15743"
          x1="7.06126"
          y1="1.24751"
          x2="7.48245"
          y2="2.1949"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4B557" />
          <stop offset="0.453125" stopColor="brand.primary" />
          <stop offset="1" stopColor="#FD4767" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_15_15743"
          x1="6.99348"
          y1="17.9048"
          x2="7.82592"
          y2="19.0647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4B557" />
          <stop offset="0.453125" stopColor="brand.primary" />
          <stop offset="1" stopColor="#FD4767" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_15_15743"
          x1="5.42136"
          y1="5.14408"
          x2="16.0586"
          y2="11.778"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4B557" />
          <stop offset="0.453125" stopColor="brand.primary" />
          <stop offset="1" stopColor="#FD4767" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_15_15743"
          x1="14.7125"
          y1="9.38446"
          x2="15.4968"
          y2="9.76819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4B557" />
          <stop offset="0.453125" stopColor="brand.primary" />
          <stop offset="1" stopColor="#FD4767" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_15_15743"
          x1="7.03076"
          y1="6.90014"
          x2="12.9972"
          y2="11.3974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4B557" />
          <stop offset="0.453125" stopColor="brand.primary" />
          <stop offset="1" stopColor="#FD4767" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_15_15743"
          x1="1.62941"
          y1="4.34563"
          x2="11.2779"
          y2="7.57074"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4B557" />
          <stop offset="0.453125" stopColor="brand.primary" />
          <stop offset="1" stopColor="#FD4767" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_15_15743"
          x1="9.86608"
          y1="3.08828"
          x2="10.4502"
          y2="3.67912"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4B557" />
          <stop offset="0.453125" stopColor="brand.primary" />
          <stop offset="1" stopColor="#FD4767" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_15_15743"
          x1="5.36231"
          y1="5.36307"
          x2="15.4521"
          y2="11.68"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4B557" />
          <stop offset="0.453125" stopColor="brand.primary" />
          <stop offset="1" stopColor="#FD4767" />
        </linearGradient>
        <clipPath id="clip0_15_15743">
          <rect
            width="16"
            height="18"
            fill="white"
            transform="translate(0 2.03711) rotate(-7.31282)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
};
