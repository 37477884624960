import {
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const transparent = defineStyle((props) => {
  return {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "brand.secondary1",
    color: mode('brand.secondary2', 'brand.primary')(props),
    background: 'transparent',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '14px'
  };
});

const gradient = defineStyle((props) => {
  return {
    border: 'none',
    color: mode('brand.secondary1', '#1B1C31')(props),
    background: mode(
      '#6B30BA',
      'linear-gradient(136.53deg, #FDE033 15.46%, #EE4373 85.69%)'
    )(props),
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '14px'
  };
});

const normal = defineStyle((props) => {
  return {
    border: 'none',
    color: mode('brand.secondary1', 'brand.secondary2')(props),
    background: mode('#6B30BA', 'brand.primary')(props),
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '14px'
  };
});

const outline = defineStyle((props) => {
  return {
    color: mode('#6B30BA', 'brand.primary')(props),
    background: 'transparent',
    fontSize: '16px',
    lineHeight: '17px',
    border: mode('1px solid #000', '1px solid #fff')(props),
    outline: mode('1px solid #000', '1px solid #fff')(props),
    _hover: { backgroundColor: 'brand.secondary3' }
  };
});

export const buttonTheme = defineStyleConfig({
  defaultProps: {
    variant: 'normal'
  },
  variants: {
    transparent,
    gradient,
    normal,
    outline
  },
  sizes: {
    xs: {
      padding: '5px 29px'
    },
    sm: {
      padding: '10px 16px'
    },
    md: {
      padding: '7px 45px'
    },
    lg: {
      padding: '11px 83px'
    }
  }
});
