import React, { useEffect } from 'react';
import { Box, BoxProps, Flex, Grid, useColorModeValue } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { Route, Routes } from 'react-router-dom';
import { dashboardViewAtom, navbarColorAtom } from 'src/atoms';
import { CompletedContracts } from 'src/components/dashboard/CompletedContracts';
import { CompletedContractsCondensed } from 'src/components/dashboard/CompletedContractsCondensed';
import { AccountSettings } from '../components/dashboard/AccountSettings';
import { ActionRequired } from '../components/dashboard/ActionRequired';
import { ActionRequiredCondensed } from '../components/dashboard/ActionRequiredCondensed';
import { Greeting } from '../components/dashboard/Greeting';
import { PaymentHistory } from '../components/dashboard/PaymentHistory';
import { SentOffers } from '../components/dashboard/SentOffers';
import { SentOffersCondensed } from '../components/dashboard/SentOffersCondensed';
import { Membership } from '../components/dashboard/Membership';
import { Sidebar } from '../components/dashboard/Sidebar';

export interface DashboardProps extends BoxProps {}
export const Dashboard: React.FC<DashboardProps> = ({ ...props }) => {
  const bg = useColorModeValue(
    'linear-gradient(#FFD3F8, #DCFFF0)',
    'linear-gradient(#654A61, #4A655A)'
  );
  const containerBg = useColorModeValue(
    'rgba(235, 235, 235, 0.45)',
    'rgba(27, 28, 49, 0.5)'
  );

  const [view] = useAtom(dashboardViewAtom);
  const [, setNavbarColor] = useAtom(navbarColorAtom);

  useEffect(() => {
    setNavbarColor(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <Box
      background={bg}
      h={{ base: '100vh', lg: '100vh' }}
      position="relative"
      overflowY={{base: "auto", lg:"hidden"}}
      _before={{
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgImage: 'url(/a.png)',
        bgRepeat: 'no-repeat',
        bgPosition: '110% 50%',
        bgSize: '50%'
      }}
      {...props}
    >
      <Flex
        h={{ base: 'auto', lg: '100vh' }}
        overflowY={{ base: 'auto', lg: 'hidden' }}
        flexDirection="column"
        position="relative"
      >
        <Flex h="100%" paddingBottom={{ base: '0', lg: '0' }}>
          <Sidebar
            h="100%"
            overflowY="auto"
            display={{ base: 'none', lg: 'initial' }}
          />
          <Flex
            height="100%"
            flex={1}
            flexDirection="column"
            overflowY="auto"
            mx={{ base: '0px', lg: '24px' }}
          >
            <Greeting />
            <Flex
              flex="1"
              m={{base: "24px 0 0 0", lg:"24px 0"}}
              overflow="auto"
              borderRadius="5px"
              bg={containerBg}
              sx={{ backdropFilter: 'blur(120px)' }}
              filter="drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25))"
              flexDirection="column"
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    <Box m="24px 20px">
                      {view === 'Main' ? (
                        <>
                          <ActionRequiredCondensed />
                          <Grid
                            templateColumns={{
                              base: 'repeat(1, 1fr)',
                              lg: 'repeat(2, 1fr)'
                            }}
                            gap={{ lg: '18px' }}
                          >
                            <SentOffersCondensed />
                            <CompletedContractsCondensed />
                          </Grid>
                        </>
                      ) : view === 'SentOffers' ? (
                        <SentOffers />
                      ) : view === 'ActionRequired' ? (
                        <ActionRequired />
                      ) : view === 'CompletedContracts' ? (
                        <CompletedContracts />
                      ) : (
                        <Box></Box>
                      )}
                    </Box>
                  }
                />
                <Route path="/settings" element={<AccountSettings />} />
                <Route path="/payment-history" element={<PaymentHistory />} />
                <Route path="/membership" element={<Membership />} />
              </Routes>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
