import React, { useEffect, useState } from 'react';
import {
  FlexProps,
  Flex,
  useColorModeValue,
  Heading,
  Text,
  Button,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from 'src/atoms';
import {
  getContractsCountByStatus,
  getOtherPartyUnfinishedNegotiationsCount,
  getUserUnfinishedNegotiationsCount
} from 'src/api';

export interface GreetingProps extends FlexProps {}

export const Greeting: React.FC<GreetingProps> = ({}) => {
  const color = useColorModeValue('brand.secondary2', 'brand.secondary1');
  const btnColor = useColorModeValue('#6B30BA', 'brand.primary');
  const btnBg = useColorModeValue('brand.secondary1', 'brand.secondary2');

  const navigate = useNavigate();

  const [counts, setCounts] = useState<{
    completedContracts: number;
    negotiationsInProgress: number;
    pendingSignature: number;
  }>({
    completedContracts: 0,
    negotiationsInProgress: 0,
    pendingSignature: 0
  });

  const [user] = useAtom(userAtom);

  useEffect(() => {
    const getCounts = async () => {
      const result = await Promise.all([
        getContractsCountByStatus('both-signed'),
        getUserUnfinishedNegotiationsCount(),
        getOtherPartyUnfinishedNegotiationsCount()
      ]);

      setCounts({
        completedContracts: result[0].data.count,
        negotiationsInProgress: result[1].data.count + result[2].data.count,
        pendingSignature: 0
      });
    };

    getCounts();
  }, [setCounts]);

  const { completedContracts, negotiationsInProgress } = counts;

  return (
    <Flex
      justifyContent="space-between"
      pt="24px"
      alignItems={{ base: 'center', lg: 'flex-end' }}
      flexDir={{ base: 'column', lg: 'row' }}
    >
      <Flex flexDir="column" alignItems={{ base: 'center', lg: 'flex-start' }}>
        <Heading
          fontSize="48px"
          fontWeight="400"
          lineHeight="48px"
          color={color}
          marginBottom="7px"
        >
          Hello, {user?.firstName}
        </Heading>
        <Flex
          gap="11px"
          flexDirection={{ base: 'column', lg: 'row' }}
          alignItems={{ base: 'center', lg: 'flex-start' }}
        >
          <Text color={color}>Need more credits?</Text>
          <Button
            color={btnColor}
            background={btnBg}
            borderRadius="25px"
            h="24px"
            width="88px"
            onClick={() => navigate('/dashboard/membership')}
          >
            Upgrade
          </Button>
        </Flex>
      </Flex>

      <Flex gap="25px" mt={{ base: '25px', lg: '0' }}>
        <Flex
          gap="8px"
          alignItems="center"
          justifyContent="center"
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Text
            color={color}
            fontSize={{ base: '32px', lg: '40px' }}
            fontWeight="700"
            lineHeight="16px"
            alignItems="center"
          >
            {completedContracts}
          </Text>
          <Text color={color}>
            Completed
            <br />
            Contracts
          </Text>
        </Flex>

        <Flex
          gap="8px"
          alignItems="center"
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Text
            color={color}
            fontSize={{ base: '32px', lg: '40px' }}
            fontWeight="700"
            lineHeight="16px"
          >
            {negotiationsInProgress}
          </Text>
          <Text color={color}>
            Negotiations
            <br />
            in process
          </Text>
        </Flex>

        <Flex
          gap="8px"
          alignItems="center"
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Text
            color={color}
            fontSize={{ base: '32px', lg: '40px' }}
            fontWeight="700"
            lineHeight="16px"
          >
            0
          </Text>
          <Text color={color}>
            Pending for
            <br />
            signature
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
