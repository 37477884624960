import { Box, BoxProps, Flex, useColorModeValue, Text, Image } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { logoutUser } from 'src/api';
import { userAtom } from 'src/atoms';
import { DemoVideoContext } from '../../providers/demoVideoProvider';
import { Clef } from '../icons/Clef';
import { Home } from '../icons/Home';
import { Logout } from '../icons/Logout';
import { Money } from '../icons/Money';
import { Settings } from '../icons/Settings';
import { Youtube } from '../icons/Youtube';

export interface SidebarProps extends BoxProps {}

export const Sidebar: React.FC<SidebarProps> = ({ ...props }) => {
  const bg = useColorModeValue(
    'rgba(255, 255, 255, 0.7)',
    'rgba(0, 1, 22, 0.5)'
  );
  const highlight = useColorModeValue('#6B30BA', 'brand.primary');
  const color = useColorModeValue('brand.secondary2', 'brand.secondary1');
  const selectedColor = useColorModeValue('brand.secondary1', 'brand.secondary2');

  const navigate = useNavigate();
  const [, setUser] = useAtom(userAtom);

  const logout = async () => {
    setUser(null);
    try {
      await logoutUser();
    } catch {
    } finally {
      navigate('/login');
    }
  };

  const { showVideo } = useContext(DemoVideoContext);

  return (
    <Box
      bg={bg}
      pt="92px"
      pb="68px"
      sx={{ backdropFilter: 'blur(120px)' }}
      w="250px"
      {...props}
    >
      <NavLink to={'/dashboard'} end>
        {({ isActive }) => (
          <Flex
            pl="14px"
            py="17px"
            gap="20px"
            background={isActive ? highlight : ''}
            alignItems="center"
          >
            <Home color={isActive ? selectedColor : color} h="18px" w="16px" />
            <Text
              fontSize="16px"
              fontWeight="600"
              lineHeight="20px"
              color={isActive ? selectedColor : color}
            >
              Dashboard
            </Text>
          </Flex>
        )}
      </NavLink>
      <Flex
        pl="14px"
        py="17px"
        gap="20px"
        alignItems="center"
        mt="27px"
        cursor="pointer"
        onClick={() => showVideo()}
      >
        <Youtube color={color} h="24px" w="24px" />
        <Text fontSize="16px" fontWeight="600" lineHeight="20px" color={color}>
          Demo Video
        </Text>
      </Flex>
      <NavLink to={'/dashboard/membership'} end>
        {({ isActive }) => (
          <Flex
            pl="14px"
            py="17px"
            gap="20px"
            alignItems="center"
            mt="27px"
            background={isActive ? highlight : ''}
          >
            <Money color={isActive ? selectedColor : color} h="18px" w="16px" />
            <Text
              fontSize="16px"
              fontWeight="600"
              lineHeight="20px"
              color={isActive ? selectedColor : color}
            >
              Upgrade Plan
            </Text>
          </Flex>
        )}
      </NavLink>
      <a href="https://blog.getcharta.com/" target="_blank">
        <Flex pl="14px" py="17px" gap="20px" alignItems="center" mt="27px">
          {/* <ChartaU color={color} h="18px" w="16px" /> */}
          <Image src="./chartaU.png" h="18px" w="18px"/>
          <Text
            fontSize="16px"
            fontWeight="600"
            lineHeight="20px"
            color={color}
          >
            Charta U
          </Text>
        </Flex>
      </a>
      <NavLink to={'/dashboard/settings'}>
        {({ isActive }) => (
          <Flex
            pl="14px"
            py="17px"
            gap="20px"
            alignItems="center"
            mt="27px"
            background={isActive ? highlight : ''}
          >
            <Settings
              color={isActive ? selectedColor : color}
              h="18px"
              w="16px"
            />
            <Text
              fontSize="16px"
              fontWeight="600"
              lineHeight="20px"
              color={isActive ? selectedColor : color}
            >
              Account Settings
            </Text>
          </Flex>
        )}
      </NavLink>
      <a href="https://getcharta.com/contact" target="_blank">
        <Flex pl="14px" py="17px" gap="20px" alignItems="center" mt="27px">
          <Clef color={color} h="18px" w="16px" />
          <Text
            fontSize="16px"
            fontWeight="600"
            lineHeight="20px"
            color={color}
          >
            Contact Us
          </Text>
        </Flex>
      </a>
      {/* <NavLink to={"/dashboard/payment-history"}>
        {({ isActive }) => (
          <Flex
            pl="14px"
            py="17px"
            gap="20px"
            alignItems="center"
            mt="27px"
            background={isActive ? highlight : ""}
          >
            <Money
              color={isActive ? selectedColor : color}
              h="18px"
              w="16px"
            />
            <Text
              fontSize="16px"
              fontWeight="600"
              lineHeight="20px"
              color={isActive ? selectedColor : color}
            >
              Payment History
            </Text>
          </Flex>
        )}
      </NavLink> */}
      <Flex
        pl="14px"
        py="17px"
        gap="20px"
        alignItems="center"
        mt="27px"
        cursor="pointer"
        onClick={() => logout()}
      >
        <Logout color={color} h="18px" w="16px" />
        <Text fontSize="16px" fontWeight="600" lineHeight="20px" color={color}>
          Sign Out
        </Text>
      </Flex>
    </Box>
  );
};
